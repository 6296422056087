import { Alert, Modal, ModalHeader, ModalBody, ModalFooter, Button, Spinner } from 'reactstrap';

type Props = {
  title: string,
  body: string,
  errorMsg?: string,
  succcessMsg?: string,
  inProgress?: boolean,
  isOpen: boolean,
  posBtnTitle?: string,
  negBtnTitle?: string
  centered?: boolean,
  onClickPositive: () => void,
  onClickNegative?: () => void
}
const AlertModal = (props: Props) => (
  <Modal isOpen={props.isOpen} centered={props.centered}>
    <ModalHeader>{props.title}</ModalHeader>
    <ModalBody>
      <h6>{props.body}</h6>
      {props.errorMsg && <Alert color='danger' className='mt-4'>{props.errorMsg}</Alert>}
      {props.succcessMsg && <Alert color='success' className='mt-4'>{props.succcessMsg}</Alert>}
    </ModalBody>
    <ModalFooter className=''>
      {props.onClickNegative && <Button color='secondary' onClick={props.onClickNegative}>{props.negBtnTitle ? props.negBtnTitle : 'No'}</Button>}
      <Button color='danger' onClick={props.onClickPositive}>
        {
          props.inProgress &&
          <Spinner size='sm' />
        }
        {
          !props.inProgress && (props.posBtnTitle ? props.posBtnTitle : 'Yes')
        }
      </Button>
    </ModalFooter>
  </Modal>
);

export default AlertModal;
