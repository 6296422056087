import React, { useState, useImperativeHandle } from 'react';
import { InputGroup, InputGroupText, Input } from 'reactstrap';
import lockIcon from '../../assets/lock.png';
import emailIcon from '../../assets/email.png';

const WithPassword = React.forwardRef((props, ref) => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');

    useImperativeHandle(ref, () => ({
        getLoginInfo: () => ({ username, password })
    }))

    return (
        <>
            <InputGroup className='mt-2'>
                <InputGroupText>
                    <img alt='...' src={emailIcon} width={25} height={25} />
                </InputGroupText>
                <Input placeholder='Username or Email' value={username} onChange={e => setUsername(e.target.value)} />
            </InputGroup>
            <InputGroup className='mt-3'>
                <InputGroupText>
                    <img alt='...' src={lockIcon} width={25} height={25} />
                </InputGroupText>
                <Input placeholder='Password' type='password' value={password} onChange={e => setPassword(e.target.value)} />
            </InputGroup>
        </>
    )
})

export default WithPassword;