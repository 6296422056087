import { PayPalButton } from "react-paypal-button-v2";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

const SANDBOX_CLIENT_ID = 'AbMZdTlYwHN0ctnbfMdo7xu-iriWk3z0ahVfjl9k8YadOo_VpOxiildZhBBpWmtHf8BVps6YrpUTI-S9';
const SANDBOX_PLAN_ID = 'P-5A405179N0979923VMGYWO7A';
type Props = {
    isOpen: boolean,
    centered?: boolean,
    toggle?: () => void,
    serverUrl: string
}

const PaypalPaymentModel = (props: Props) => {

  const _onApprove = (data:any, actions:any) => {
    return actions.subscription.get().then(function(details:any) {
      alert("Subscription completed");
    //   console.log({window,data,details,actions})
    //   return fetch(`${props.serverUrl}onPaypalSubscription`, {
    //     method: "post",
    //     body: JSON.stringify({
    //       orderId: data.orderID,
    //       subscriptionId: data.subscriptionID,
    //       planId: details.plan_id,
    //       subscriber: details.subscriber,
    //       amount: details.billing_info.last_payment.amount.value, 
    //       paymentTime: details.billing_info.last_payment.time, 
    //       nextPaymentTime: details.billing_info.last_payment.next_billing_time, 
    //     })
    //   });
    });
  }

  const _onError = (err:any) => {
    console.log('On Error: ', err);
    alert("Error occured. Try again")
  }

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle} centered>
        <ModalHeader className='justify-content-center'>
            Posh Sidekick Paypal Subscription
        </ModalHeader>
        <ModalBody>
            <PayPalButton
            style={{ marginHorizontal: 16 }}
            options={{ 
                vault: true, 
                clientId: SANDBOX_CLIENT_ID, 
                disableFunding: 'card', 
                intent: 'subscription'
            }}
            createSubscription={(data:any, actions:any) => {
                return actions.subscription.create({
                plan_id: SANDBOX_PLAN_ID
                });
            }}
            shippingPreference="NO_SHIPPING"
            onApprove={_onApprove}
            onError={_onError}
            
            />
        </ModalBody>
    </Modal>
  );
}

export default PaypalPaymentModel

