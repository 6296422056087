import { create } from 'zustand';
import { devtools } from 'zustand/middleware';
import UserInfo from './interfaces/UserInfo'
import { BillingDetailsInformation } from './interfaces/BillingDetails';

interface PoshMarkAccount {
    id: number,
    pmUserId: string
    selected: 0 | 1
}
interface UserInfoLoginWithEmail {
    user: UserInfo,
    accouns: Array<PoshMarkAccount>
}

interface PoshMarkInterface {
    userAccount: UserInfoLoginWithEmail | null
    selectedPmUserId: null | string
    billingDetails: BillingDetailsInformation[]
    changeBillingDetails: (billingDetails: BillingDetailsInformation[]) => void
    setSelectedPmUserId: (id: string) => void,
    changeUser: (user: UserInfoLoginWithEmail) => void
}

export const usePoshSideKick = create<PoshMarkInterface>()(
    devtools(
        set => ({
            userAccount: null,
            selectedPmUserId: null,
            billingDetails: [],
            changeBillingDetails(billingDetails) {
                set({ billingDetails })
            },
            setSelectedPmUserId: (id) => {
                set(() => {
                    return ({ selectedPmUserId: id })
                })
            },
            changeUser: (user) => {
                set(() => {
                    return { userAccount: user }
                })
            }
        }),
        {
            name: 'game-storage',
        },
    ),
);
