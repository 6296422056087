import { useEffect, useState } from "react";
import { 
    Alert, 
    Col, 
    Row, 
    Card, 
    CardHeader, 
    CardBody, 
    CardTitle, 
    Button, 
    CardText, 
    Container, 
    ButtonGroup,
    InputGroup,
    Input,
    Badge
} from "reactstrap";
import StripePaymentModal from "../components/payment/StripePaymentModal";
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from "@stripe/react-stripe-js";
import { useLocation, useNavigate } from "react-router-dom";
import UserInfo, { CLUserInfo, PSKUserInfo } from "../interfaces/UserInfo";
import PoshAccount from "../interfaces/PoshAccount";
import PaymentHandler from "../classes/PaymentHandler";
import Subscription from "../interfaces/Subscription";
import moment from "moment";
import LoadingButton from "../components/LoadingButton";
import { upperFirst, snakeCase, uniqBy, sortBy, remove } from 'lodash';
import AlertModal from "../components/AlertModal";
import ChoosePaymentModal from "../components/payment/ChoosePaymentModal";
import PaypalPaymentModal from "../components/payment/PaypalPaymentModal";
import axios from "axios";
import AskReasonModal from "../components/AskReasonModal";
import { usePoshSideKick } from "../poshSideKickStorage";
import { OverAllSubscription, PromotedPaymentIntent } from "../interfaces/OverAllSubscription";
import { BillingDetailsInformation } from "../interfaces/BillingDetails";
import { BsFillTrashFill } from "react-icons/bs"
import PlanCard from "../components/payment/PlanCard";
import { PLAN_FEATURES, PLAN_MODE, PLAN_TITLES } from "../consts/Plans";
import { getCurrencySymbol, getPlanModeFromProductId } from "../components/payment/utils";
import ContinueWithPaymentMethodModal from "../components/ContinueWithPaymentMethodModal";

let stripeKey = process.env.REACT_APP_STRIPE_PUBLISH_KEY;
if (!stripeKey) {
    stripeKey = "pk_test_51JLARNFAgxGOpyBoaWcNpm1YMxrHQ8JC9zgciAGvvomnuXxJSFOboK1ZTnVAn76NZYxxX9vfwnazDQJrXuJzy6VE00du2t2Jni";
}

const stripePromise = loadStripe(stripeKey);
const Payment = () => {
    const [iOSAlert, setIOSAlert] = useState(true);
    const [country, setCountry] = useState('US');
    const [openPaymentChooser, setOpenPaymentChooser] = useState(false);
    const [openStripePayment, setOpenStripePayment] = useState(false);
    const [openPaypalPayment, setOpenPaypalPayment] = useState(false);
    const [activePlanId, setActivePlanId] = useState<string | undefined>(undefined);
    const [activePlanTitle, setActivePlanTitle] = useState<string>('');
    const [referredCode, setReferredCode] = useState<number | string | undefined>('');
    const [usedFirstAffiliate, setUsedFirstAffiliate] = useState<boolean>(false);
    const [pskUserInfo, setPSKUserInfo] = useState<PSKUserInfo | null>(null);
    const [clUserInfo, setCLUserInfo] = useState<CLUserInfo | null>(null);
    const [pmAccounts, setPMAccounts] = useState<PoshAccount[]>([]);
    const [selectedPmaccount, setSelectedPmaccount] = useState<string | undefined>(undefined)
    //TODO: show paypal subscription if it is done 
    const [isPaying, setIsPaying] = useState(false);
    const [subscriptionV2, setSubscriptionV2] = useState<OverAllSubscription | null>(null)
    const [SKSubscription, setSKSubscription] = useState<OverAllSubscription | undefined>(undefined);
    const [CLOnlySubscription, setCLOnlySubscription] = useState<OverAllSubscription | undefined>(undefined);
    const [paymentDetails, setPaymentDetails] = useState<Array<BillingDetailsInformation> | null>(null)
    const [planMode, setPlanMode] = useState(PLAN_MODE.PSK_CL);
    const [readTerms, setreadTerms] = useState(false);
    const [showUnsubscribeModal, setShowUnsubscribeModal] = useState(false);
    const [unsubscribeAlert, setUnsubscribeAlert] = useState('');
    const [showNSUnsubcribeModal, setShowNSUnsubcribeModal] = useState(false)
    const [unsubscribing, setUnsubscribing] = useState(false);
    const [unsubscribeNSAlert, setUnsubscribeNSAlert] = useState('');
    const [showConfirmPaymentMethod, setShowConfirmPaymentMethod] = useState(false);
    const [showConfirmUpgradePlan, setShowConfirmUpgradePlan] = useState(false);
    
    const location = useLocation();
    const { changeBillingDetails } = usePoshSideKick()
    const navigate = useNavigate();

    useEffect(() => {
        const state = location.state as { user: UserInfo, pskPMAccounts: PoshAccount[] };

        const pskUserInfo = state?.user.psk;
        const pmAccounts = state?.pskPMAccounts;
        const clUserInfo = state?.user.cl;
        if (pskUserInfo) {
            setPSKUserInfo(pskUserInfo);
        }
        if (clUserInfo) {
            setCLUserInfo({
                ...clUserInfo,
                serverUrl: state?.user.clServerUrl
            });
        }
        
        setReferredCode(state?.user.referredCode);
        setUsedFirstAffiliate(state?.user.usedFirstAffiliate);

        if (pmAccounts) {
            setPMAccounts(pmAccounts);
            setSelectedPmaccount(pmAccounts.find(p => p.selected === 1)?.pmUserId)
        }

        if (!pskUserInfo?.id || !clUserInfo?.id) {
            window.alert("Please re-login on the app first and then try again.");
            navigate('/', { replace: true });
        }
    }, [location.state, location.search, navigate]);

    useEffect(() => {
        const getSubscriptions = async () => {
            try {
                if (!pskUserInfo?.id || !clUserInfo?.id) return;
                const pskPaymentHandler = new PaymentHandler<Subscription[]>(pskUserInfo!.serverUrl!);
                const clPaymentHandler = new PaymentHandler<Subscription>(clUserInfo!.serverUrl!);
                const [subscriptions, clSubscription, paymentMethods] = await Promise.all([
                    pskPaymentHandler.getSubscriptionV2(pskUserInfo?.id),
                    clPaymentHandler.getSubscription(clUserInfo?.id),
                    pskPaymentHandler.getPaymentDetails(pskUserInfo?.id)
                ])
                if (paymentMethods?.length > 0) {
                    let uniqueCards = sortBy(uniqBy(paymentMethods, "card.last4"), ["isDefault"]);
                    setPaymentDetails(uniqueCards)
                    changeBillingDetails(uniqueCards)
                }
                
                if (subscriptions) {
                    const skSubscription = subscriptions.find(s => s.productId?.includes("sidekick"));
                    const clOnlySubscription = subscriptions.find(s => s.productId?.includes("crosslisting"))
                    setSKSubscription(skSubscription);
                    setCLOnlySubscription(clOnlySubscription ?? clSubscription);
                    if (skSubscription || clOnlySubscription) {
                        setreadTerms(true);
                    }
                } else if (clSubscription) {
                    setCLOnlySubscription(clSubscription);
                }
            } catch (e) {
                if (e instanceof Error) {
                    window.alert(e.message);
                } else if (typeof e === 'string') {
                    window.alert(e);
                }
            }
        }
        if (pskUserInfo) {
            getSubscriptions();
        }
    }, [pskUserInfo, clUserInfo, changeBillingDetails]);

    useEffect(() => {
        // Get current location
        axios.get("https://api.ipify.org/?format=json").then(resp => {
            const { ip } = resp.data;
            if (!ip) {
                setCountry('US');
            }
            axios.get("https://ipapi.co/" + ip + '/json').then(resp => {
                const { country_code } = resp.data;
                if (country_code !== 'US' && country_code !== 'GB') {
                    setCountry('US');
                } else {
                    setCountry(country_code);
                }
            })
        })
    }, []);

    const onClkPayNow = async (planTitle: string, count: number) => {
        if (!pskUserInfo?.id) {
            return;
        }
        if (readTerms === false) {
            window.alert("Please read and accept the terms of service and privacy policy.");
            return;
        }
        
        const targetPlanId = snakeCase(planTitle) + "_" + count;
        setActivePlanId(targetPlanId);
        setActivePlanTitle(planTitle);
        
        const paymentMethod = paymentDetails?.find(p => !!p.isDefault) ?? paymentDetails?.[0];
        const targetPlanMode = getPlanModeFromProductId(targetPlanId);
        if (targetPlanMode === PLAN_MODE.PSK_CL && pmAccounts.length === 0) {
            window.alert("Please link your poshmark account first.");
            return;
        }

        const isOldCLPlan = targetPlanMode === PLAN_MODE.CL && !!CLOnlySubscription?.isOldPlan;

        if (isOldCLPlan) {
            // Unsubscribe the old plan first
            setIsPaying(true);
            try {
                const paymentHandler = new PaymentHandler(clUserInfo!.serverUrl!);
                await paymentHandler.cancelSubscription(pskUserInfo?.id, CLOnlySubscription!.subscriptionId!);
                setCLOnlySubscription(undefined);
            } catch (e) {
                if (e instanceof Error) {
                    window.alert(e.message);
                } else if (typeof e === 'string') {
                    window.alert(e);
                }
                return;
            } finally {
                setIsPaying(false);
            }
        }
        if (!paymentMethod) {
            setOpenStripePayment(true);
        } else {
            let subscription;
            
            if (targetPlanMode === PLAN_MODE.PSK_CL) {
                subscription = SKSubscription;
            } else {
                subscription = CLOnlySubscription;
            }
            if ((targetPlanMode === PLAN_MODE.PSK_CL && SKSubscription?.productId) || (targetPlanMode === PLAN_MODE.CL && CLOnlySubscription?.productId && !CLOnlySubscription?.isOldPlan)) {
                if (subscription?.productId === targetPlanId) {
                    window.alert('This is the plan you already purchased.');
                    return;
                }
                if (targetPlanMode === PLAN_MODE.PSK_CL) {
                    if (count < pmAccounts.length) {
                        window.alert('You need to unlink some poshmark closets first.')
                        return;
                    }
                }
                
                if (!subscription?.subscriptionId) {
                    window.alert('You dont have active subscription. Please refresh the page and try again.');
                    return;
                }
                setShowConfirmUpgradePlan(true);
            } else {
                setShowConfirmPaymentMethod(true);
            }
        }
    }

    const onConfirmedUpgradeSubscription = async () => {
        try {
            if (!activePlanId || !pskUserInfo?.id) {
                window.alert('You dont have active subscription. Please refresh the page and try again.');
                return;
            }
            const targetPlanMode = getPlanModeFromProductId(activePlanId);
            if (targetPlanMode === PLAN_MODE.PSK_CL && pmAccounts.length === 0) {
                window.alert("Please link your poshmark account first.");
                return;
            }

            let subscription;
            if (targetPlanMode === PLAN_MODE.PSK_CL) {
                subscription = SKSubscription;
            } else {
                subscription = CLOnlySubscription;
            }
            if (!subscription?.subscriptionId) {
                window.alert('You dont have active subscription. Please refresh the page and try again.');
                return;
            }

            setIsPaying(true);
            const paymentHandler = new PaymentHandler<Subscription>(pskUserInfo!.serverUrl!);
            const upgradedSub = await paymentHandler.upgradeSubscription(pskUserInfo?.id, subscription.subscriptionId, activePlanId);
            if (targetPlanMode === PLAN_MODE.PSK_CL) {
                setSKSubscription(upgradedSub);
            } else if (targetPlanMode === PLAN_MODE.CL) {
                setCLOnlySubscription(upgradedSub);
            }
            setActivePlanId(undefined);
            setActivePlanTitle("");
            setShowConfirmUpgradePlan(false);
        } catch (e) {
            if (e instanceof Error) {
                window.alert(e.message);
            } else if (typeof e === 'string') {
                window.alert(e);
            }
        } finally {
            setIsPaying(false);
        }
    }

    const onPaymentSuccess = () => {
        if (!pskUserInfo?.id) return;
        setActivePlanId(undefined);
        setActivePlanTitle("");
        if (!usedFirstAffiliate && !!referredCode) {
            setUsedFirstAffiliate(true);
        }

        const paymentHandler = new PaymentHandler<Subscription>(pskUserInfo!.serverUrl!);
        paymentHandler.getSubscriptionV2(pskUserInfo?.id).then(subscriptions => {
            setSKSubscription(subscriptions.find(s => s.productId?.includes("sidekick")));
            setCLOnlySubscription(subscriptions.find(s => s.productId?.includes("crosslisting")));
        });
    }

    const onAddNewPaymentMethod = (method: BillingDetailsInformation) => {
        setOpenStripePayment(false);
        setPaymentDetails(prev => {
            if (prev) {
                if (!prev.find(p => p.isDefault)) {
                    method.isDefault = true;
                }
                return sortBy(uniqBy([...prev, method], "card.last4"), ["isDefault"]);
            } else {
                return prev;
            }
        })
    }

    const onConfirmUnsubscribe = async (productId: string | undefined, reason: Array<{ reason: string; answer?: string }>) => {
        try {
            if (!pskUserInfo?.id || !clUserInfo?.id || !productId) {
                return;
            }
            let subscription;
            const targetPlanMode = getPlanModeFromProductId(productId);
            if (targetPlanMode === PLAN_MODE.PSK_CL) {
                subscription = SKSubscription;
            } else if (targetPlanMode === PLAN_MODE.CL) {
                subscription = CLOnlySubscription;
            }
            if (!subscription?.subscriptionId) {
                return;
            }
            let paymentHandler;
            setUnsubscribing(true);
            if (targetPlanMode === PLAN_MODE.CL && subscription.isOldPlan) {
                paymentHandler = new PaymentHandler(clUserInfo!.serverUrl!);
                await paymentHandler.cancelSubscription(clUserInfo?.id, subscription?.subscriptionId);
            } else {
                paymentHandler = new PaymentHandler(pskUserInfo!.serverUrl!);
                await paymentHandler.cancelSubscription(pskUserInfo?.id, subscription?.subscriptionId, reason);
            }
            
            if (targetPlanMode === PLAN_MODE.PSK_CL) {
                setSKSubscription(undefined);
            } else if (targetPlanMode === PLAN_MODE.CL){
                setCLOnlySubscription(undefined);
            }
            setActivePlanId(undefined);
            setShowUnsubscribeModal(false);
        } catch (e) {
            if (e instanceof Error) {
                setUnsubscribeAlert(e.message);
            } else if (typeof e === 'string') {
                setUnsubscribeAlert(e);
            }
        } finally {
            setUnsubscribing(false);
        }
    }

    const onConfirmUnsubscribeNetworkShare = async (productId: string | undefined, reason: Array<{ reason: string; answer?: string }>) => {
        try {
            if (!pskUserInfo?.id) {
                return;
            }
            const paymentHandler = new PaymentHandler(pskUserInfo!.serverUrl!);
            if (selectedPmaccount) {
                await paymentHandler.cancelSubscriptionNetworkShare(selectedPmaccount, reason);
            }
            setSubscriptionV2(null);
            setShowNSUnsubcribeModal(false);
        } catch (e) {
            if (e instanceof Error) {
                setUnsubscribeNSAlert(e.message);
            } else if (typeof e === 'string') {
                setUnsubscribeNSAlert(e);
            }
        }
    }

    const onCouponApplied = () => {
        if (!pskUserInfo?.id) return;
        const paymentHandler = new PaymentHandler<Subscription>(pskUserInfo!.serverUrl!);
        paymentHandler.getSubscriptionV2(pskUserInfo?.id).then(subscriptions => {
            setSKSubscription(subscriptions.find(s => s.productId?.includes("sidekick")));
            setCLOnlySubscription(subscriptions.find(s => s.productId?.includes("crosslisting")));
        });
    }

    const onDefaultCardChanged = (defaultPaymentMethodId: string) => {
        setPaymentDetails(prev => {
            if (prev) {
                return sortBy(prev.map(p => {
                    if (p.id === defaultPaymentMethodId) {
                        p.isDefault = true;
                    } else {
                        p.isDefault = false;
                    }
                    return { ...p };
                }), ["isDefault"]);
            } else {
                return prev;
            }
        })
    }

    const onRemovedCard = (removedPaymentMethodId: string) => {
        setPaymentDetails(prev => {
            if (prev) {
                remove(prev, p => p.id === removedPaymentMethodId);
                return sortBy(prev, ["isDefault"]);
            } else {
                return prev;
            }
        })
    }

    return (
        <Elements stripe={stripePromise} options={{ fonts: [{ cssSrc: 'https://fonts.googleapis.com/css?family=Poppins:400' }] }}>
            <Container className="my-4" fluid>
                <h4 className="mt-4"><strong>Choose Your Plan</strong></h4>
                <Container style={{ fontSize: "12px", marginTop: "5px", marginBottom: "5px", width: "90%" }}>
                    All pricing is based in USD, countries other than USA are
                    subject to conversion and exchange rates
                </Container>
                <ButtonGroup className="mt-2">
                    <Button outline={planMode === PLAN_MODE.CL} onClick={() => setPlanMode(PLAN_MODE.PSK_CL)} className="shadow-none">{PLAN_MODE.PSK_CL}</Button>
                    <Button outline={planMode === PLAN_MODE.PSK_CL} onClick={() => setPlanMode(PLAN_MODE.CL)} className="shadow-none">{PLAN_MODE.CL}</Button>
                </ButtonGroup>
                <Container className="mt-3">
                    <div style={{ display: 'flex', gap: 10, justifyContent: 'center' }}>
                        <input checked={readTerms} onChange={(e) => setreadTerms(e.target.checked)} type="checkbox" />
                        <div style={{ fontSize: '14px' }}>
                            I agree&nbsp;
                            <a href="https://poshsidekick.com/eula/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#3A5579' }}>Terms of Service</a>
                            &nbsp;and&nbsp;
                            <a href="https://poshsidekick.com/privacy-policy/" target="_blank" rel="noreferrer" style={{ textDecoration: 'none', color: '#3A5579' }}>Privacy Policy</a>
                        </div>
                    </div>
                </Container>
                {
                    pskUserInfo?.device === 'iphone' && iOSAlert &&
                    <Alert className="mt-2" toggle={() => setIOSAlert(false)}>
                        <strong>If you are an iphone user, please keep these in mind.</strong>{<br />}{<br />}
                        1. If you already subscribed through in-app purchase, please unsubscribe it first and try here.{<br />}{<br />}
                        2. If you subscribed through this page, you will be considered as a subscriber and you can continue using the app.{<br />}{<br />}
                        3. Manage your subscription here if you subscribed through this page
                    </Alert>
                }
                <Row>
                    <Col xs="12" xl="3">
                        {
                            [SKSubscription, CLOnlySubscription].map((subscription, index) => (
                                subscription && 
                                <CardSubscription
                                    key={subscription.productId} 
                                    pskUserInfo={pskUserInfo}
                                    clUserInfo={clUserInfo}
                                    header={subscription.productId?.includes("crosslisting") ? "Crosslisting Monthly Subscription" : "Sidekick Monthly Subscription"} 
                                    stripeSubscription={subscription} 
                                    unsubscribeFunction={(productId) => {
                                        setActivePlanId(productId);
                                        setShowUnsubscribeModal(true)
                                    }}
                                    onCouponApplied={onCouponApplied}
                                    onPayedIncompleteInvoice={onPaymentSuccess} />
                            ))
                        }
                        {
                            SKSubscription && SKSubscription.promotedInvoice &&
                            <CardSubscription 
                                pskUserInfo={pskUserInfo} 
                                clUserInfo={clUserInfo} 
                                header="Promoted Listings" 
                                stripeSubscription={SKSubscription} 
                                unsubscribeFunction={() => setShowNSUnsubcribeModal(true)} 
                                onCouponApplied={onCouponApplied} />
                        }
                        {
                            (paymentDetails && paymentDetails.length > 0 && pskUserInfo) &&
                            paymentDetails.map((payment) => {
                                return <CardPayment key={payment.id}
                                            hasActiveSubscription={!!subscriptionV2 || !!SKSubscription || !!CLOnlySubscription}
                                            userInfo={pskUserInfo} 
                                            paymentsDetail={payment} 
                                            AllPaymentDetails={paymentDetails} 
                                            onDefaultCardChanged={onDefaultCardChanged} 
                                            onRemovedCard={onRemovedCard} />
                            })
                        }
                        {
                            <Button onClick={() => setOpenStripePayment(true)} style={{ backgroundColor: '#3A5579', width: '100%'}} className="mb-0">
                                Add New Payment Method
                            </Button>
                        }
                    </Col>
                    <Col xs="12" xl="9">
                        {
                            planMode === PLAN_MODE.PSK_CL && 
                            <Row>
                                {
                                    [PLAN_TITLES.PSK, PLAN_TITLES.SK_BASIC, PLAN_TITLES.SK_SILVER, PLAN_TITLES.SK_GOLD, PLAN_TITLES.SK_PRO, PLAN_TITLES.SK_ENTERPRISE].map((title, index) => (
                                        <Col key={title} xs="12" sm="6" xl="4" className="mt-2">
                                            <PlanCard 
                                                title={title} 
                                                subscribedProductId={SKSubscription?.productId}
                                                targetProductId={activePlanId}
                                                features={PLAN_FEATURES[title]} 
                                                isPaying={isPaying} 
                                                onClkPayNow={onClkPayNow}
                                                country={country}
                                                planMode={planMode} />
                                        </Col>
                                    ))
                                }
                            </Row>
                        }
                        {
                            planMode === PLAN_MODE.CL &&
                            <Row>
                                {
                                    [PLAN_TITLES.CL_BASIC, PLAN_TITLES.CL_SILVER, PLAN_TITLES.CL_GOLD, PLAN_TITLES.CL_PRO, PLAN_TITLES.CL_ENTERPRISE].map((title, index) => (
                                        <Col key={title} xs="12" sm="6" xl="4" className="mt-2">
                                            <PlanCard 
                                                title={title} 
                                                subscribedProductId={CLOnlySubscription?.productId}
                                                targetProductId={activePlanId}
                                                features={PLAN_FEATURES[title]}
                                                isPaying={isPaying}
                                                onClkPayNow={onClkPayNow}
                                                country={country}
                                                planMode={planMode} />
                                        </Col>
                                    ))
                                }
                            </Row>
                        }
                    </Col>
                </Row>
            </Container>
            {
                !!openPaymentChooser &&
                <ChoosePaymentModal
                    onClickStripe={() => setOpenStripePayment(true)}
                    onClickPaypal={() => setOpenPaypalPayment(true)}
                    isOpen={!!openPaymentChooser}
                    toggle={() => setOpenPaymentChooser(false)}
                    centered
                />
            }
            {
                !!openStripePayment &&
                <StripePaymentModal isOpen={!!openStripePayment}
                    userId={pskUserInfo?.id}
                    referredCode={referredCode}
                    usedFirstAffiliate={usedFirstAffiliate}
                    email={pskUserInfo?.email}
                    phone={pskUserInfo?.phone}
                    customerId={pskUserInfo?.sCusId}
                    productId={activePlanId}
                    planTitle={activePlanTitle}
                    planMode={planMode}
                    serverUrl={pskUserInfo!.serverUrl!}
                    country={country}
                    toggle={() => {
                        setOpenStripePayment(false);
                        setActivePlanId(undefined);
                        setActivePlanTitle('');
                    }}
                    onPaymentSuccess={() => {
                        setOpenStripePayment(false);
                        onPaymentSuccess()
                    }}
                    onAddNewPaymentMethod={onAddNewPaymentMethod} />
            }
            {
                !!showConfirmPaymentMethod && 
                <ContinueWithPaymentMethodModal 
                    isOpen={showConfirmPaymentMethod}
                    paymentMethods={paymentDetails}
                    productId={activePlanId}
                    planMode={planMode}
                    country={country}
                    serverUrl={pskUserInfo?.serverUrl}
                    userId={pskUserInfo?.id}
                    referredCode={referredCode}
                    usedFirstAffiliate={usedFirstAffiliate}
                    pmAccountCount={pmAccounts.length}
                    activeSubscription={subscriptionV2}
                    toggle={() => {
                        setShowConfirmPaymentMethod(false);
                        setActivePlanId(undefined);
                        setActivePlanTitle("");
                    }}
                    onPaymentSuccess={() => {
                        setShowConfirmPaymentMethod(false);
                        setActivePlanId(undefined);
                        setActivePlanTitle("");
                        onPaymentSuccess();
                    }}
                    onUpgradedSubscription={(upgradedSub) => {
                        setShowConfirmPaymentMethod(false);
                        setActivePlanId(undefined);
                        setActivePlanTitle("");
                        setSubscriptionV2(upgradedSub);
                    }}
                />
            }
            {
                !!openPaypalPayment &&
                <PaypalPaymentModal isOpen={!!openPaypalPayment}
                    toggle={() => {
                        setOpenPaypalPayment(false)
                    }}
                    serverUrl={pskUserInfo!.serverUrl!}
                    centered />
            }
            {
                !!showUnsubscribeModal &&
                <AskReasonModal alert={unsubscribeAlert} 
                    productId={activePlanId}
                    dismissAlert={() => setUnsubscribeAlert('')}
                    networkShare={false} 
                    onConfirmUnsubscribe={onConfirmUnsubscribe} 
                    unsubscribing={unsubscribing}
                    modal={showUnsubscribeModal} 
                    toggle={() => {
                        setActivePlanId(undefined);
                        setShowUnsubscribeModal(!showUnsubscribeModal)
                    }} />
            }
            {
                !!unsubscribeNSAlert &&
                <AskReasonModal alert={unsubscribeNSAlert} 
                    productId={activePlanId}
                    dismissAlert={() => setUnsubscribeNSAlert('')}
                    networkShare={true} 
                    onConfirmUnsubscribe={onConfirmUnsubscribeNetworkShare} 
                    unsubscribing={unsubscribing}
                    modal={showNSUnsubcribeModal} 
                    toggle={() => {
                        setActivePlanId(undefined);
                        setShowNSUnsubcribeModal(!showNSUnsubcribeModal)
                    }} />
            }
            {
                !!showConfirmUpgradePlan &&
                <AlertModal
                    title="Change Plan"
                    body="You are about to change your plan. Are you sure?"
                    isOpen={!!showConfirmUpgradePlan}
                    onClickPositive={onConfirmedUpgradeSubscription}
                    onClickNegative={() => {
                        setActivePlanId(undefined);
                        setActivePlanTitle("");
                        setShowConfirmUpgradePlan(false);
                    }}
                    inProgress={isPaying} />
            }
        </Elements>
    )
}

const CardSubscription = ({ 
    stripeSubscription, 
    unsubscribeFunction, 
    header, 
    pskUserInfo,
    clUserInfo,
    onCouponApplied,
    onPayedIncompleteInvoice
}: { 
    stripeSubscription: OverAllSubscription, 
    unsubscribeFunction: (productId: string | undefined) => void, 
    header: string, 
    pskUserInfo: PSKUserInfo | null,
    clUserInfo: CLUserInfo | null,
    onCouponApplied: () => void,
    onPayedIncompleteInvoice?: () => void
}) => {
    const paymentHandler = new PaymentHandler(pskUserInfo!.serverUrl!);
    const invoice = header.includes("Promoted") ? stripeSubscription.promotedInvoice : stripeSubscription.normalInvoice
    const createEpochTime = invoice.effective_at * 1000
    const dueDateAddMonth = new Date(createEpochTime);
    dueDateAddMonth.setMonth(dueDateAddMonth.getMonth() + 1);
    const dueDate = new Date(createEpochTime);
    const invoiceIncompleted = invoice.amount_remaining !== 0;
    const [showAlertPaynow, setshowAlertPaynow] = useState(false)
    const [messagePaynow, setMessagePaynow] = useState<{ error: string | null, success: string | null }>({ error: null, success: null })
    const [couponCode, setCouponCode] = useState("");
    const [applyingCoupon, setApplyingCoupon] = useState(false);
    const [payingIncompleted, setPayingIncompleted] = useState(false);
    const isPromoted = header.includes("Promoted")
    const { billingDetails } = usePoshSideKick()

    const payNormalSubscriptionBalance = async () => {
        if (billingDetails.length === 0) {
            setMessagePaynow({ error: "Sorry! You need to set a default payment method.", success: null })
            return;
        }
        const defaultPaymentMethod = billingDetails.find(paymentMethod => paymentMethod.isDefault);
        if (!defaultPaymentMethod) {
            setMessagePaynow({ error: 'Please set a default payment method first.', success: null })
            return;
        }
        try {
            if (!stripeSubscription.normalPaymentIntent) {
                setMessagePaynow({ error: 'Cannot find the incompleted payment intent.', success: null });
                return;
            }
            setPayingIncompleted(true);
            const paymentIntentId = (stripeSubscription.normalPaymentIntent as PromotedPaymentIntent).id;
            await paymentHandler.payNowSubscription(paymentIntentId, defaultPaymentMethod.id, isPromoted ? "promoted" : "normal")
            if (onPayedIncompleteInvoice) {
                onPayedIncompleteInvoice();
            }
            setshowAlertPaynow(false);
        } catch (err: any) {
            const errorResponse = err?.response?.data?.message
            setMessagePaynow({ error: errorResponse ? errorResponse : "Something went wrong. Please try again later.", success: null })
        } finally {
            setPayingIncompleted(false);
        }
    }

    const onRedeemCoupon = async () => {
        if (!pskUserInfo?.serverUrl || !clUserInfo?.serverUrl || !stripeSubscription?.subscriptionId) {
            return;
        }
        
        if (couponCode.length === 0) {
            window.alert("Please enter a valid coupon code!");
            return;
        }
        try {
            setApplyingCoupon(true);
            let paymentHandler = new PaymentHandler(pskUserInfo?.serverUrl);
            if (stripeSubscription.isOldPlan && stripeSubscription.productId?.includes("crosslisting")) {
                paymentHandler = new PaymentHandler(clUserInfo?.serverUrl)
            }
            await paymentHandler.getCouponDetails(couponCode);
            await paymentHandler.applyCoupon(stripeSubscription.subscriptionId, couponCode);
            setCouponCode("");
            window.alert("Coupon applied successfully.");
            onCouponApplied();
        } catch (e) {
            if (e instanceof Error) {
                window.alert(e.message);
                return;
            } else if (typeof e === 'string') {
                window.alert(e);
                return;
            }
        } finally {
            setApplyingCoupon(false);
        }
    }

    return (
        <Card body className="p-0 my-2">
            {
                !!showAlertPaynow &&
                <AlertModal
                    title="Pay Now"
                    body="You are about to pay your outstanding balance to resume your subscription."
                    isOpen={!!showAlertPaynow}
                    onClickPositive={() => payNormalSubscriptionBalance()}
                    onClickNegative={() => {
                        setMessagePaynow({ error: null, success: null });
                        setshowAlertPaynow(false)
                    }}
                    inProgress={payingIncompleted}
                    errorMsg={messagePaynow.error ? messagePaynow.error : ""}
                    succcessMsg={messagePaynow.success ? messagePaynow.success : ""}
                />
            }
            <CardHeader>
                <strong>{header}</strong>
                {invoiceIncompleted && <Badge color="danger" style={{ marginLeft: 8, verticalAlign: 'text-top' }}>Incompleted</Badge>}
            </CardHeader>
            <CardBody>
                <CardTitle tag="h5">
                    <strong>
                        {getCurrencySymbol(invoice.currency)}{((invoice.amount_due as number) / 100).toFixed(2)}
                    </strong>
                </CardTitle>
                <CardText>
                    {invoiceIncompleted ? 'Retry' : 'Renewal'} at {moment(invoiceIncompleted ? dueDate : dueDateAddMonth).format('MMM DD, YYYY')} for {getCurrencySymbol(stripeSubscription.upcomingInvoice.currency)}{((stripeSubscription.upcomingInvoice.amount_due) / 100).toFixed(2)}
                </CardText>
                {
                    !invoiceIncompleted &&
                    <InputGroup className="mx-auto" style={{ width: "90%" }}>
                        <Input value={couponCode} onChange={e => setCouponCode(e.target.value)} placeholder="Coupon Code" />
                        <LoadingButton onClick={onRedeemCoupon} className="w-auto m-0" style={{ backgroundColor: '#FF6D60' }} loading={applyingCoupon}>Redeem Coupon</LoadingButton>
                    </InputGroup>
                }
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', marginTop: 16 }}>
                    {
                        invoiceIncompleted &&
                        <Button onClick={() => setshowAlertPaynow(true)} style={{ backgroundColor: '#3A5579', width: '90%' }} className="mb-2">
                            <h6 className="mb-0">Pay Now</h6>
                        </Button>

                    }
                    <Button className="border-0" onClick={() => unsubscribeFunction(stripeSubscription.productId)} style={{ backgroundColor: '#FF6D60', width: '90%' }}>
                        Unsubscribe
                    </Button>
                </div>
            </CardBody>
        </Card>
    )
}

const CardPayment = ({ paymentsDetail, AllPaymentDetails, hasActiveSubscription, userInfo, onRemovedCard, onDefaultCardChanged }: { 
    onRemovedCard: (paymentMethodId: string) => void, 
    onDefaultCardChanged: (defaultPaymentMethodId: string) => void, 
    userInfo: PSKUserInfo, 
    paymentsDetail: BillingDetailsInformation, 
    AllPaymentDetails: Array<BillingDetailsInformation>,
    hasActiveSubscription: boolean
}) => {
    const { card } = paymentsDetail
    const [showChangeDefaultCard, setshowChangeDefaultCard] = useState(false)
    const [showRemoveCard, setshowRemoveCard] = useState(false)
    const [removingCard, setRemovingCard] = useState(false);
    const [changingDefault, setChangingDefault] = useState(false);
    const [messageRemoveCard, setMessageRemoveCard] = useState<{ error: string | null, success: string | null }>({ error: null, success: null })
    const [messageDefaultCard, setMessageDefaultCard] = useState<{ error: string | null, success: string | null }>({ error: null, success: null })

    const paymentHandler = new PaymentHandler(userInfo.serverUrl ? userInfo.serverUrl : '');

    const removecard = async () => {
        try {
            setRemovingCard(true);
            await paymentHandler.removeCard(userInfo.id as number, paymentsDetail.id)
            onRemovedCard(paymentsDetail.id);
        } catch (error: any) {
            setMessageRemoveCard({ error: "Sorry! Something went wrong with your request.", success: null })
        } finally {
            setshowRemoveCard(false);
            setRemovingCard(false);
        }
    }

    const setDefaultCard = async () => {
        try {
            setChangingDefault(true);
            await paymentHandler.defaultCard(userInfo.id as number, paymentsDetail.id)
            onDefaultCardChanged(paymentsDetail.id)
        } catch (error: any) {
            setMessageDefaultCard({ error: "Sorry! Something went wrong with your request.", success: null })
        } finally {
            setshowChangeDefaultCard(false);
            setChangingDefault(false);
        }

    }

    return (
    <Card style={{ maxHeight: "fit-content", width: "100%" }} className="p-0 my-2">
        {
            !!showChangeDefaultCard &&
            <AlertModal
                title="Change Default Card" 
                body="You are going to update the default card for monthly payments. Are you sure?"
                isOpen={showChangeDefaultCard}
                inProgress={changingDefault}
                errorMsg={messageDefaultCard.error ? messageDefaultCard.error : ""}
                succcessMsg={messageDefaultCard.success ? messageDefaultCard.success : ""}
                onClickPositive={() => setDefaultCard()}
                onClickNegative={() => {
                    setshowChangeDefaultCard(false)
                }}
            />
        }
        {
            !!showRemoveCard &&
            <AlertModal
                title="Remove Card" 
                body="You are going to remove card. Are you sure?"
                inProgress={removingCard}
                isOpen={showRemoveCard}
                errorMsg={messageRemoveCard.error ? messageRemoveCard.error : ""}
                succcessMsg={messageRemoveCard.success ? messageRemoveCard.success : ""}
                onClickPositive={() => removecard()}
                onClickNegative={() => {
                    setshowRemoveCard(false)
                }}
            />
        }
        <CardHeader style={{ backgroundColor: paymentsDetail.isDefault ? "blanchedalmond" : "white" }}>
            <strong>{upperFirst(card.brand)}</strong>
            {
                ((!paymentsDetail.isDefault && AllPaymentDetails.find(i => i.isDefault)) || !hasActiveSubscription || paymentsDetail.isExpired) &&
                <BsFillTrashFill onClick={() => setshowRemoveCard(true)} color="red" style={{ position: "absolute", right: 10, top: 10 }} />
            }
            {
                paymentsDetail.isExpired && <Badge color="danger" style={{ verticalAlign: 'text-top', marginLeft: 8 }}>Expired</Badge>
            }
        </CardHeader>
        <CardBody>
            <CardTitle tag="h5" style={{display: "flex", alignItems: "center", justifyContent: "center", flexDirection: "column"}}>

                <strong>
                    **** **** **** {card.last4}
                </strong>
                {
                    !paymentsDetail.isDefault && !paymentsDetail.isExpired &&
                    <div style={{display: "flex"}}>
                        <input checked={!!paymentsDetail.isDefault} onChange={(value) => {
                                setshowChangeDefaultCard(value.target.checked);
                            }} type="checkbox" style={{ marginRight: 15 }} />
                        <div style={{fontSize: "12px"}}>Mark as default</div>
                    </div>
 
                }
            </CardTitle>
            <CardText>
                {card.exp_month} /{' '}
                {card.exp_year}
            </CardText>
        </CardBody>
    </Card>
    )
}
export default Payment;