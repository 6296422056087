import {Modal, ModalHeader, ModalBody, Button } from 'reactstrap';

type Props = {
    isOpen: boolean,
    centered?: boolean,
    onClickStripe: () => void,
    onClickPaypal?: () => void,
    toggle?: () => void,

}
const PaymentChooserModal = (props: Props) => (
  <Modal isOpen={props.isOpen} toggle={props.toggle} centered={props.centered}>
    <ModalHeader>Payment method</ModalHeader>
    <ModalBody>
      <h5 style={{textAlign: "center"}}>Select payment method</h5>
      <div style={{display: "flex", justifyContent: "space-evenly"}}>
        <Button color='primary' onClick={props.onClickStripe}>
        Card Payment
      </Button>
        <Button color='secondary' onClick={props.onClickPaypal}>
        Paypal
      </Button>
      </div>
    </ModalBody>
    
  </Modal>
);

export default PaymentChooserModal;
