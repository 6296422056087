import { useState } from 'react';
import { getAuth, signInWithPopup, GoogleAuthProvider } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import app from '../../consts/firebase';
import { Buffer } from 'buffer';
import AuthHandler from '../../classes/AuthHandler';
// Initialize Firebase Auth
const auth = getAuth(app);

// Helper function to convert hex string to Uint8Array and truncate to 32 bytes (256 bits)
const hexStringToUint8Array = (hex: string, byteLength: number) => {
    const bytes = new Uint8Array(byteLength);
    for (let i = 0; i < byteLength * 2; i += 2) {
        bytes[i / 2] = parseInt(hex.substr(i, 2), 16);
    }
    return bytes;
};


interface GoogleSigninComponentProps {
    setAlert: React.Dispatch<React.SetStateAction<string>>;
}

function GoogleSigninComponent({ setAlert }: GoogleSigninComponentProps) {
    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    // Retrieve and convert the encryption key (truncate to 256 bits) and IV from environment variables
    const commonKey = hexStringToUint8Array(process.env.REACT_APP_COMMON_KEY || '7396467efbf43ca44c606abb2394ccbbcc82b9ece9c28f7faf64368b9bb0181dbea8f659efc2631e2db4fc35de28bccda37c5bee2ecc43511577b2072c7880ce', 32); // 32 bytes (256 bits)
    const commonIv = hexStringToUint8Array(process.env.REACT_APP_COMMON_IV || 'fd23eaea50bac140cd88c175b0cc19fa', 16); // 16 bytes (128 bits)

    const encryptText = async (text: string) => {
        const encoder = new TextEncoder();
        const iv = commonIv.length ? commonIv : crypto.getRandomValues(new Uint8Array(16));

        // Import the key for AES encryption
        const cryptoKey = await crypto.subtle.importKey(
            'raw',
            commonKey,
            { name: 'AES-CBC' },
            false,
            ['encrypt']
        );

        // Encrypt the text
        const encrypted = await crypto.subtle.encrypt(
            { name: 'AES-CBC', iv },
            cryptoKey,
            encoder.encode(text)
        );

        // Convert the encrypted data to Base64 string using Buffer
        const encryptedBase64 = Buffer.from(new Uint8Array(encrypted)).toString('base64');

        return {
            iv: Array.from(iv),
            encryptedText: encryptedBase64 // Base64 encrypted text as a string
        };
    };



    const handleGoogleSignin = async () => {
        setLoading(true);
        const provider = new GoogleAuthProvider();
        const authHandler = new AuthHandler();
        try {
            // Use Firebase's built-in Google Sign-In for web
            const result = await signInWithPopup(auth, provider);

            // Access the Google Access Token and user data
            const credential = GoogleAuthProvider.credentialFromResult(result);
            const user = result.user;
            const email = user.email;

            // Encrypt the user's email
            const { encryptedText } = await encryptText(email as string);

            if (!encryptedText) {
                showToast("Google Sign-In Error: Encryption failed", 'error');
                return;
            }

            // Send the encrypted email to the server
            const userInfo = await authHandler.loginWithSocialMedia(encryptedText, 'google');
            // Navigate to payment page, passing the user data
            navigate('/payment', { state: userInfo });
        } catch (e: any) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
        } finally {
            setLoading(false);
        }
    };

    const showToast = (message: string, type: string) => {
        setAlert(`${type}: ${message}`);
    };

    return (
        <button className='btn btn-primary' onClick={handleGoogleSignin} disabled={loading}>
            {loading ? (
                <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </>
            ) : (
                'Google'
            )}
        </button>
    );
}

export default GoogleSigninComponent;
