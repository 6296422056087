import axios from 'axios';
import React, { useEffect } from 'react';
import { Buffer } from 'buffer';
import { useNavigate } from 'react-router-dom';
import AuthHandler from '../../classes/AuthHandler';
import { facebookConfig } from "../../facebook.config"

declare global {
    interface Window {
        fbAsyncInit: () => void;
        FB: {
            init: (params: { appId: string; cookie: boolean; xfbml: boolean; version: string }) => void;
            login: (callback: (response: any) => void, options: { scope: string }) => void;
        };
    }
}

const hexStringToUint8Array = (hex: string, byteLength: number) => {
    const bytes = new Uint8Array(byteLength);
    for (let i = 0; i < byteLength * 2; i += 2) {
        bytes[i / 2] = parseInt(hex.substr(i, 2), 16);
    }
    return bytes;
};

interface FacebookSignInProps {
    setAlert: React.Dispatch<React.SetStateAction<string>>;
}


const FacebookSignIn: React.FC<FacebookSignInProps> = ({ setAlert }) => {
    const navigate = useNavigate();
    const [loading, setLoading] = React.useState(false);

    useEffect(() => {
        // Load the Facebook SDK
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: facebookConfig.appId,
                cookie: true,
                xfbml: true,
                version: 'v10.0'
            });
        };

        (function (d, s, id) {
            const fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            const js: HTMLScriptElement = d.createElement(s) as HTMLScriptElement;
            js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            if (fjs && fjs.parentNode) {
                fjs.parentNode.insertBefore(js, fjs);
            }
        }(document, 'script', 'facebook-jssdk'));
    }, []);

    const commonKey = hexStringToUint8Array(process.env.REACT_APP_COMMON_KEY || '7396467efbf43ca44c606abb2394ccbbcc82b9ece9c28f7faf64368b9bb0181dbea8f659efc2631e2db4fc35de28bccda37c5bee2ecc43511577b2072c7880ce', 32); // 32 bytes (256 bits)
    const commonIv = hexStringToUint8Array(process.env.REACT_APP_COMMON_IV || 'fd23eaea50bac140cd88c175b0cc19fa', 16); // 16 bytes (128 bits)

    const encryptText = async (text: string) => {
        const encoder = new TextEncoder();
        const iv = commonIv.length ? commonIv : crypto.getRandomValues(new Uint8Array(16));

        // Import the key for AES encryption
        const cryptoKey = await crypto.subtle.importKey(
            'raw',
            commonKey,
            { name: 'AES-CBC' },
            false,
            ['encrypt']
        );

        // Encrypt the text
        const encrypted = await crypto.subtle.encrypt(
            { name: 'AES-CBC', iv },
            cryptoKey,
            encoder.encode(text)
        );

        // Convert the encrypted data to Base64 string using Buffer
        const encryptedBase64 = Buffer.from(new Uint8Array(encrypted)).toString('base64');

        return {
            iv: Array.from(iv),
            encryptedText: encryptedBase64 // Base64 encrypted text as a string
        };
    };

    const facebookSignIn = async () => {
        const authHandler = new AuthHandler();
        setLoading(true);
        try {
            window.FB.login((response: any) => { // Access FB from window object
                if (response.authResponse) {
                    const accessToken = response.authResponse.accessToken;
                    fetch(`https://graph.facebook.com/v10.0/me?fields=email,name&access_token=${accessToken}`)
                        .then(userInfoResponse => userInfoResponse.json())
                        .then(userDetails => {
                            const email = userDetails.email;
                            return encryptText(email as string).then(({ encryptedText }) => {
                                if (!encryptedText) return null;
                                return authHandler.loginWithSocialMedia(encryptedText, 'facebook');
                            });
                        })
                        .then(userInfo => {
                            if (!userInfo) return;
                            // Navigate to payment page, passing the user data
                            navigate('/payment', { state: userInfo });
                        })
                        .catch(e => {
                            if (e instanceof Error) {
                                setAlert(e.message);
                            } else if (typeof e === 'string') {
                                setAlert(e);
                            }
                        });
                } else {
                    setAlert('User cancelled login or did not fully authorize.');
                }
            }, { scope: 'public_profile,email' });
        } catch (e) {
            if (e instanceof Error) {
                setAlert(e.message);
            } else if (typeof e === 'string') {
                setAlert(e);
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <button className='btn btn-primary' onClick={facebookSignIn}>
            {loading ? (
                <>
                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                    Loading...
                </>
            ) : (
                'Facebook'
            )}
        </button>
    );
};

export default FacebookSignIn;